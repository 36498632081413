@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-chat-bg {
  background-color: #e5ddd5;
}

.loader {
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}